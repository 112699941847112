import styled from 'styled-components';
import { MEDIA_QUERIES } from '@indico-data/permafrost';

export const StyledPageContainer = styled.div`
  .container {
    margin: 0 10px;

    @media ${MEDIA_QUERIES.mediumScreen} {
      margin: 20px 50px;
      padding-bottom: 20px;
    }
  }
`;

export const StyledDetailPageContainer = styled.div`
  height: 100%;
  box-sizing: border-box;

  .container {
    box-sizing: border-box;
    margin: 20px;
    height: calc(100% - 20px);
  }
`;
