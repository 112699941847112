import { COLORS, TYPOGRAPHY } from 'Permafrost/constants';
import styled from 'styled-components';

export const StyledContainer = styled.div<{ $isFixed: boolean }>`
  display: flex;
  flex-direction: column;

  width: ${(props) => (props.$isFixed ? '100%' : 'fit-content')};
  height: 100%;
`;

export const StyledTableContainer = styled.div<{ $isFixed: boolean }>`
  position: relative;
  display: ${(props) => (props.$isFixed ? 'block' : 'flex')};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
  height: 100%;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.regalBlue};
    transition: background-color 0.2s;

    border-radius: 10px;

    &:hover {
      background-color: ${COLORS.chatamsBlue};
    }
  }

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

export const StyledTable = styled.table<{ $isFixed: boolean }>`
  border-collapse: separate;
  margin: 0;
  table-layout: auto;
  border-spacing: 0;
`;

export const StyledThead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 100;

  background-color: ${COLORS.blackPearl};

  tr:first-child {
    th {
      &:first-child {
        border-top-left-radius: 8px;
      }

      &:last-child {
        border-top-right-radius: 8px;
      }
    }
  }
`;

export const StyledTh = styled.th<{
  $noPadding: boolean;
  $width: number;
  $hasDefinedColumnSize: boolean;
}>`
  max-width: ${(props) => (props.$hasDefinedColumnSize ? `${props.$width}px` : '300px')};
  width: ${(props) => (props.$hasDefinedColumnSize ? `${props.$width}px` : 'auto')};
  padding: ${(props) => (props.$noPadding ? 0 : `0 8px`)};
  color: #7488a4;
  font-size: ${TYPOGRAPHY.fontSize.base};

  border-top: 1px solid #92a8c24d;
  border-bottom: 1px solid #92a8c24d;
  border-right: 1px solid #92a8c24d;

  user-select: none;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:first-child {
    border-left: 1px solid #92a8c24d;
  }
`;

export const StyledTbody = styled.tbody`
  tr:last-child {
    td {
      &:first-child {
        border-bottom-left-radius: 8px;
      }

      &:last-child {
        border-bottom-right-radius: 8px;
      }
    }
  }
`;

export const StyledBodyTr = styled.tr<{ $isSelected: boolean }>`
  background: ${(props) => (props.$isSelected ? `#112341` : `transparent`)};
`;

export const StyledTd = styled.td<{
  $noPadding: boolean;
  $textAlign?: 'left' | 'center' | 'right';
  $width: number;
  $hasDefinedColumnSize: boolean;
}>`
  padding: ${(props) => (props.$noPadding ? 0 : `5px 8px`)};
  font-size: ${TYPOGRAPHY.fontSize.base};
  font-weight: 500;
  color: ${COLORS.white};
  max-width: ${(props) => (props.$hasDefinedColumnSize ? `${props.$width}px` : '300px')};
  width: ${(props) => (props.$hasDefinedColumnSize ? `${props.$width}px` : 'auto')};

  border-right: 1px solid #92a8c24d;
  border-bottom: 1px solid #92a8c24d;

  text-align: ${(props) => props.$textAlign ?? 'left'};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:first-child {
    border-left: 1px solid #92a8c24d;
  }
`;
export const CenteredRow = styled.td`
  text-align: center;
  vertical-align: middle;
  height: 100%;
  border: 1px solid #92a8c24d;
  svg {
    margin: 28px auto;
    display: block;
  }
`;
