import React, { useEffect } from 'react';
import { useLazyQuery } from 'react-apollo';
import gql from 'graphql-tag';

import { AppChrome } from 'root/components/AppChrome';
import { useCurrentUser } from 'root/hooks/authentication';

import { StyledPageContainer, StyledDetailPageContainer } from './PageContainer.styles';

type Props = {
  module: string; // should be an enum 'NOCT_APP' - the id of the object in menu.tsx
  children?: React.ReactNode;
  variant?: 'default' | 'detail';
};

export const PageContainer = ({ module, children, variant = 'default' }: Props) => {
  const [loadIPAVersion, { data }] = useLazyQuery(GET_IPA_VERSION);
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (currentUser) {
      loadIPAVersion();
    }
  }, [currentUser]);

  const version = data?.ipaVersion;
  // Convert IPA-4.9.0.rc1 => 4.9.0 --- needs to also work for... IPA-4.10.47.rc23 => 4.10.47
  const abbreviatedVersion = version
    ?.split('IPA-')?.[1]
    ?.split('.')
    .filter((num: string, i: number, a: string[]) => i !== a.length - 1)
    .join('.');

  const Container = variant === 'detail' ? StyledDetailPageContainer : StyledPageContainer;

  return (
    <Container>
      <AppChrome
        data-cy="pageContainer"
        selectedModule={module}
        version={{ full: version, abbr: abbreviatedVersion }}
      >
        <div data-cy="pageContainerSelector" className="container">
          {children}
        </div>
      </AppChrome>
    </Container>
  );
};

const GET_IPA_VERSION = gql`
  query getIPAVersion {
    ipaVersion
  }
`;
