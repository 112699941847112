import styled from 'styled-components';
import { COLORS, TYPOGRAPHY } from 'Permafrost/constants';

export const StyledNoResults = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  position: sticky;
  left: 0;
  margin: 30px auto;

  p {
    font-size: ${TYPOGRAPHY.fontSize.subheadSmall};
    font-weight: 500;
    color: ${COLORS.white};

    span {
      color: ${COLORS.summerSky};
      cursor: pointer;
    }
  }
`;
